import axios from 'axios';
import qs from 'qs';
import Services from './services';

function paramSerialise(params) {
    // Qs is already included in the Axios package
    return qs.stringify(params, {
        arrayFormat: "brackets",
        encode: false
    });
}

export default class NetService {
    static prepareUri(uri, params) {
        let re = /\{([\w\-]+)\}/g;
        let match = re.exec(uri);
        let keys = new Set();
        while (match) {
            keys.add(match[1]);
            match = re.exec(uri);
        }
        for (let key of keys) {
            uri = uri.replace(new RegExp(`\{${key}\}`), params[key]);
            delete params[key];
        }
        return {uri, params};
    }

    params(method, uri, params = {}, data = {}, options={}) {
        const store = Services.store();
        const { uri: newUri, params: newParams } = NetService.prepareUri(uri, params);

        const config = Object.assign(
            {
                url: newUri,
                method,
                params: newParams,
                // mode: 'cors',
                // withCredentials: false,
                headers: {
                    Accept: 'application/json',
                },
                paramsSerializer: paramSerialise,
            },
            options
        );
        if (['post', 'put'].includes(method)) {
            if (config.headers['Content-Type'] === 'text/xml') {
                config.headers['Content-Type'] = 'text/xml';
                config.data = data;
            } else if (data instanceof FormData) {
                config.data = data;
            } else {
                config.headers['Content-Type'] = 'application/json';
                config.data = JSON.stringify(data);
            }
        }

        if (store.state.env.token) {
            config.headers['Authorization'] = `Bearer ${store.state.env.token}`;
        }

        return config;
    }

    get(uri, params = {}, options={}, disableErrorAlert = false) {
        return this.request(this.params('get', uri, params, {}, options), disableErrorAlert);
    }

    post(uri, params = {}, data = {}, options = {}, disableErrorAlert = false) {
        return this.request(this.params('post', uri, params, data, options), disableErrorAlert)

    }

    put(uri, params = {}, data = {}, options={}, disableErrorAlert = false) {
        return this.request(this.params('put', uri, params, data, options), disableErrorAlert);
    }

    delete(uri, params = {}, options={}, disableErrorAlert = false) {
        return this.request(this.params('delete', uri, params, {}, options), disableErrorAlert);
    }

    request(params, disableErrorAlert = false) {
        return axios.request(params).then(resp => {
            if (resp.status >= 200 && resp.status < 300) {
                return resp.data;
            }
        }).catch(error => {
            let errorMsg = '';
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                errorMsg = error.response.data.message;

                let errors = error.response.data.errors;
                if (errors) {
                    Object.keys(errors).forEach(key => {
                        errorMsg = errors[key];
                    })
                }

                if (!errorMsg && error.response.status === 413) {
                    errorMsg = "Слишком большой файл";
                }
                if (!errorMsg && error.response.status === 424) {
                    errorMsg = "Нельзя удалить товарную группу, пока в ней содержатся товары";
                }
            }

            if (!disableErrorAlert && errorMsg) {
                Services.msg(errorMsg, 'danger');
            }

            return Promise.reject(errorMsg);
        });
    }
}
