var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout" }, [
    _c(
      "div",
      { staticClass: "fake-vue-body" },
      [
        _c("LayoutHeader", { attrs: { "on-index": _vm.onIndex } }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "container-fluid" },
          [
            _c("div", { staticClass: "row flex-xl-nowrap" }, [
              _c(
                "main",
                {
                  staticClass: "flex-grow-1 no-padding",
                  class: !_vm.user.isGuest ? "col-xl-12" : "col-xl-12"
                },
                [
                  _c(
                    "div",
                    { staticClass: "container-fluid px-3 pb-5" },
                    [
                      _vm.back
                        ? _c("div", { staticClass: "mt-3" }, [
                            _c(
                              "span",
                              {
                                staticClass: "button-back",
                                on: { click: _vm.goBack }
                              },
                              [
                                _c("fa-icon", {
                                  attrs: { icon: "angle-left" }
                                }),
                                _vm._v(" Назад\n                            ")
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.hideTitle
                        ? _c("h1", { staticClass: "mt-3 mb-3" }, [
                            _vm._v(_vm._s(_vm.title))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._t("default"),
                      _vm._v(" "),
                      _c("div", { staticClass: "clearfix" })
                    ],
                    2
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("modal-message"),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loaderShow,
                    expression: "loaderShow"
                  }
                ],
                attrs: { id: "preloader" }
              },
              [_c("div", { attrs: { id: "loader" } })]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }