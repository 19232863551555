var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Header-layout" }, [
    _c(
      "header",
      {
        staticClass: "navbar navbar-dark bg-dark",
        staticStyle: { color: "#dfdfdf" }
      },
      [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("b-navbar-brand", { attrs: { href: "/", title: "Платформа" } }, [
              _c("picture", [
                _c("source", {
                  attrs: { srcset: _vm.logotypeMMS, type: "image/webp" }
                }),
                _vm._v(" "),
                _c("img", {
                  attrs: { src: _vm.logotypeMMS, width: "167", height: "45" }
                })
              ])
            ]),
            _vm._v(" "),
            _vm._m(0)
          ],
          1
        ),
        _vm._v(" "),
        !_vm.user.isGuest
          ? _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("notifications"),
                _vm._v(" "),
                _c("communication-chats-unread"),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "btn btn-dark", on: { click: _vm.logout } },
                  [_vm._v("Выйти")]
                )
              ],
              1
            )
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    !_vm.user.isGuest
      ? _c("div", { staticClass: "Header-menu" }, [_c("MainMenu")], 1)
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "d-none d-md-block" }, [
      _c(
        "span",
        {
          staticStyle: {
            "font-size": "256%",
            "line-height": "40px",
            "margin-right": "10px",
            transform: "scaleX(0.5)"
          }
        },
        [_vm._v("|")]
      ),
      _vm._v(" "),
      _c("span", { staticStyle: { color: "white" } }, [
        _vm._v(
          "\n                      Administration System\n                  "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }