import xml from './xml';

// eslint-disable-next-line import/prefer-default-export
export class EmexService {
    constructor(net) {
        this.net = net;
    }

    async find(data = {}) {
        try {
            const xmlData = xml.build(data);

            const response = await this.net.post(
                'https://home.courierexe.ru/api/',

                {},
                xmlData,
                {
                    headers: {
                        'Content-Type': 'text/xml',
                        Accept: 'text/xml',
                    },
                }
            );

            return xml.parse(response);
        } catch (error) {
            throw new Error(`API Request Error (emex find): ${error}`);
        }
    }

    async citySuggestion(data = {}, limit = 10) {
        try {
            const { townlist } = await this.find({
                townlist: { ...data, ...getEmexLimit(limit) },
            });

            if (!townlist || !townlist.town) return [];

            return prepareCityList(Array.isArray(townlist.town) ? townlist.town : [townlist.town]);
        } catch (error) {
            throw new Error(`API Request Error (emex citySuggestion): ${error}`);
        }
    }
}

function getCitySuggestion(data) {
    return {
        unrestricted_value: [...new Set([data.city.name, data.name])].join(', '),
        data,
    };
}

function prepareCityList(data) {
    return data.map(getCitySuggestion);
}

function getEmexLimit(limit = 10) {
    return {
        limit: {
            limitfrom: 0,
            limitcount: limit,
            countall: 'YES',
        },
    };
}
